var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4"},[_c('h3',{staticClass:"h3-dark mb-4"},[_vm._v("Compte en attente de validation ")]),_c('h4',{staticClass:"h4 text-primary mb-4 fw-bold"},[_vm._v("Définir votre profil : ")]),_c('ValidationObserver',{ref:"observer"},[_c('form',{attrs:{"autocomplete":"off","method":""}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"mb-3 row"},[_c('validation-provider',{attrs:{"name":"phone","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"col-lg-3 col-sm-12"},[_c('label',{staticClass:"label-dark label-lg",attrs:{"for":""}},[_vm._v("Numéro de téléphone")]),_c('VuePhoneNumberInput',{class:{
                  'form-control-light': true,
                  'is-invalid': errors.length,
                },attrs:{"placeholder":"Tél","default-country-code":"FR","clearable":true,"border-radius":15,"preferred-countries":['FR', 'BE'],"translations":{
                  countrySelectorLabel: 'Code pays',
                  countrySelectorError: 'Choisir un pays',
                  phoneNumberLabel: 'Numéro de téléphone',
                  example: 'Exemple :',
                }},on:{"update":_vm.onCompanyPhoneUpdate},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)]}}])})],1),_c('div',{staticClass:"row mb-3"},[_c('validation-provider',{attrs:{"name":"address","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"col-lg-6 col-sm-12"},[_c('atom-input',{attrs:{"placeholder":"Adresse","label":"Votre adresse postale","type":"text","input-class":{
                  'form-control': true,
                  'is-invalid': errors.length,
                },"label-class":"label-dark label-lg"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)]}}])})],1),_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"name":"country","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"col-lg-4 col-sm-12"},[(_vm.lgBreakpoint)?_c('treeselect',{class:{
                  'is-invalid': errors.length,
                },attrs:{"multiple":false,"options":_vm.countries,"no-options-text":"Aucune option disponible.","no-results-text":"Aucun résultat trouvé ...","placeholder":"Pays","normalizer":_vm.normalizer},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.country),expression:"country"}],class:{
                  'form-select': true,
                  'is-invalid': errors.length,
                },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.country=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("Pays")]),_vm._l((_vm.countries),function(item){return _c('option',{key:item.id,domProps:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)],1)]}}])})],1),_c('div',{staticClass:"row mb-3"},[_c('validation-provider',{staticClass:"col-lg-2 col-sm-12",attrs:{"name":"zipcode","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('atom-input',{attrs:{"placeholder":"CP","type":"text","input-class":{
                'form-control': true,
                'is-invalid': errors.length,
              },"label-class":"label-dark label-lg"},model:{value:(_vm.zipcode),callback:function ($$v) {_vm.zipcode=$$v},expression:"zipcode"}})]}}])}),_c('validation-provider',{staticClass:"col-lg-2 col-sm-12",attrs:{"name":"city","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":""}}),_c('atom-input',{attrs:{"placeholder":"Ville","type":"text","input-class":{
                'form-control': true,
                'is-invalid': errors.length,
              },"label-class":"d-none"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}])})],1),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-lg-4 col-sm-12"},[_c('label',{staticClass:"label label-lg",attrs:{"for":""}},[_vm._v("Votre Diplôme")]),_vm._l((_vm.userDiplomas),function(diploma,index){return _c('div',{key:'DP-' + index,staticClass:"my-2"},[_c('validation-provider',{attrs:{"name":"diplomas","rules":{ required: _vm.userDiplomas[index] != 0 }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.lgBreakpoint)?_c('treeselect',{class:{
                    'is-invalid': errors.length,
                   ' mb-4': true
                  },attrs:{"multiple":false,"options":_vm.diplomasOptions,"defaultOptions":"","no-options-text":"Aucune option disponible.","no-results-text":"Aucun résultat trouvé ...","placeholder":"Diplôme","normalizer":_vm.normalizerDiploma},model:{value:(_vm.userDiplomas[index]),callback:function ($$v) {_vm.$set(_vm.userDiplomas, index, $$v)},expression:"userDiplomas[index]"}}):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.userDiplomas[index]),expression:"userDiplomas[index]"}],class:{
                    'form-select': true,
                    'is-invalid': errors.length,
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.userDiplomas, index, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":0}},[_vm._v("Diplôme")]),_vm._l((_vm.diplomasOptions),function(diploma){return _c('option',{key:diploma.id,domProps:{"value":diploma.id}},[_vm._v(" "+_vm._s(diploma.name)+" ")])})],2),(errors.length)?_c('span'):_vm._e()]}}],null,true)}),_c('div',{staticClass:"align-items-center d-flex"},[(_vm.userDiplomas.length > 1)?_c('button',{staticClass:"btn mt-3",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteDiploma(index)}}},[_c('svg-icon',{attrs:{"icon-class":"trash","className":"trash"}})],1):_vm._e()])],1)}),_c('atom-button',{attrs:{"btn-class":"btn btn-primary-light mb-3","type":"button","title":"+ Ajouter d'autres diplômes"},on:{"click":_vm.AjouterDiplome}})],2)]),_c('div',{staticClass:"row mb-3"},[_c('validation-provider',{staticClass:"col-lg-4 col-sm-12",attrs:{"name":"job","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label-dark label-lg mt-2",attrs:{"for":""}},[_vm._v("Votre Métier")]),(_vm.lgBreakpoint)?_c('treeselect',{class:{
                'is-invalid': errors.length,
                'my-2': true,
              },attrs:{"multiple":false,"options":_vm.jobOptions,"no-options-text":"Aucune option disponible.","no-results-text":"Aucun résultat trouvé ...","placeholder":"Métier"},model:{value:(_vm.job),callback:function ($$v) {_vm.job=$$v},expression:"job"}}):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.job),expression:"job"}],staticClass:"my-2",class:{
                'form-select': true,
                'is-invalid': errors.length,
              },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.job=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("Métier")]),_vm._l((_vm.jobOptions),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(" "+_vm._s(item.label)+" ")])})],2),(errors.length)?_c('span'):_vm._e()]}}])})],1),_c('div',{staticClass:"row mb-3"},[_c('validation-provider',{staticClass:"col-lg-4 col-12",attrs:{"name":"clientType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label-dark label-lg mt-2",attrs:{"for":""}},[_vm._v("Type de client")]),(_vm.lgBreakpoint)?_c('treeselect',{class:{
                'is-invalid': errors.length,
                'my-2': true,
              },attrs:{"multiple":false,"options":_vm.clientTypes,"no-options-text":"Aucune option disponible.","no-results-text":"Aucun résultat trouvé ...","placeholder":"Type de client","normalizer":_vm.normalizerDiploma},model:{value:(_vm.clientType),callback:function ($$v) {_vm.clientType=$$v},expression:"clientType"}}):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.clientType),expression:"clientType"}],staticClass:"my-2",class:{
                'form-select': true,
                'is-invalid': errors.length,
              },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.clientType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("Type de client")]),_vm._l((_vm.clientTypes),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)]}}])})],1),_c('div',{staticClass:"row"},[_c('validation-provider',{staticClass:"col-lg-6 col-sm-12 mb-5 mb-3",attrs:{"name":"certify","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('atom-checkbox',{attrs:{"label":"J'atteste sur l'honneur l’exactitude des informations et je m'engage à fournir tout justificatif de diplôme qui me serait demandé pour suivre une formation ","link":"","label-class":{
                'form-check-label label-dark fw-bold label-sm': true,
                'is-invalid': errors.length,
              }},model:{value:(_vm.certify),callback:function ($$v) {_vm.certify=$$v},expression:"certify"}})]}}])})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-sm-12 mb-5"},[_c('atom-button',{attrs:{"btn-class":"btn btn-primary d-flex mb-5 ","type":"button","title":"Valider vos données","disabled":_vm.isNextButtonDisabled,"spinner":_vm.isNextButtonDisabled},on:{"click":_vm.next}})],1)])])])]),_c('support-box'),_c('error-modal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }