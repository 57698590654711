import lodash from 'lodash-es'
import { isImmediateTraining } from '@/utils/index'
import { getField, updateField } from 'vuex-map-fields'
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SET_CART_ITEMS,
  SET_CART_TOTAL_ITEMS,
  UPDATE_VAT_NAME,
  UPDATE_VAT_CODE,
  UPDATE_VAT,
  UPDATE_PAYMENT_TYPE,
  CLEAR_CART,
  SET_PAYMENT_PLANS,
  SET_IS_1X_ELIGIBLE,
  SET_IS_3X_ELIGIBLE,
  SET_IS_4X_ELIGIBLE,
  SHOW_NURSE,
  HIDE_NURSE,
  SET_NURSE_MESSAGE,
  SET_MERCHANT_ID,
  SET_CODE_PROMO_DATA,
} from '@/store/mutation-types'

import {
  postCart as addSessionToCart,
  postCart as removeSessionFromCart,
  save as getCartTotal,
  setOrder as setOrderData,
  setCartEvents as setCartEventsData,
  setCustomerCartBrevo as setCustomerCartBrevoData,
  setCheckoutOrder as setCheckoutOrderData,
  clear as clear,
  checkEligibility,
  createAlmaPaymentApi,
  generateJwtKey,
  setPromoCode,
} from '@/api/cart.js'

const state = {
  cartModal: '',
  cartCompanionModal: '',
  cartData: [],
  orderData: [],
  ordersRefs: [],
  cartIsLoaded: false,
  cartTotalItems: 0,
  vat: 0,
  vatCode: 'TVA0',
  vatName: '0%',
  user: {},
  paymentTerm: '1',
  paymentErrorMessage: '',
  totalOrderPrice: '',
  paymentPlans: [],
  paymentPlan: null,
  is1XEligible: false,
  is3XEligible: false,
  is4XEligible: false,
  codePromoData: {},
  showNurse: false,
  nurseMessage: '',
  objectives: '',
  nurseMessages: [
    `L'école du bien naître vous offre la possibilité de régler en 3X / 4X sans frais à partir de 2 formations!`,
    `Nous vous invitons à sélectionner votre mode de paiement.`,
  ],
  merchantId: null,
  soldableStandalone: ['FOR-CEA'],
}

const mutations = {
  updateField,
  [ADD_TO_CART](state, data) {
    state.cartData.push(data)
  },
  [REMOVE_FROM_CART](state, data) {
    state.cartData.push(data)
  },
  [SET_CART_ITEMS](state, data) {
    state.cartData = data.cartData
    state.user = data.user
    state.cartIsLoaded = true
  },
  [SET_CART_TOTAL_ITEMS](state, data) {
    state.cartTotalItems = data
  },
  [UPDATE_VAT_NAME](state, data) {
    state.vatName = data
  },
  [UPDATE_VAT](state, data) {
    state.vat = data
  },
  [UPDATE_VAT_CODE](state, data) {
    state.vatCode = data
  },
  [UPDATE_PAYMENT_TYPE](state, status) {
    state.paymentTerm = status
  },
  [CLEAR_CART](state) {
    state.cartData = []
  },
  [SET_PAYMENT_PLANS](state, data) {
    state.paymentPlans = data
  },
  [SET_IS_1X_ELIGIBLE](state, status) {
    state.is1XEligible = status
  },
  [SET_IS_3X_ELIGIBLE](state, status) {
    state.is3XEligible = status
  },
  [SET_IS_4X_ELIGIBLE](state, status) {
    state.is4XEligible = status
  },
  [SHOW_NURSE](state) {
    state.showNurse = true
  },
  [HIDE_NURSE](state) {
    state.showNurse = false
  },
  [SET_NURSE_MESSAGE](state, message) {
    state.nurseMessage = message
  },
  [SET_MERCHANT_ID](state, id) {
    state.merchantId = id
  },
  [SET_CODE_PROMO_DATA](state, data) {
    state.codePromoData = data
  },
}

const actions = {
  addToCart({ commit }, params = {}) {
    return addSessionToCart(params).then((response) => {
      if (response) {
        commit('ADD_TO_CART', params)
        return response
      }
    })
  },

  createAlmaPayment(_, params = {}) {
    return createAlmaPaymentApi(params).then((response) => {
      if (response) {
        return response
      }
    })
  },

  // eslint-disable-next-line no-unused-vars
  removeSession({ commit, dispatch }, params = {}) {
    return removeSessionFromCart(params).then((response) => {
      if (response) {
        commit('REMOVE_FROM_CART', params)
        return response
      }
    })
  },
  // eslint-disable-next-line no-unused-vars
  async setOrder({ commit }, params = {}) {
    const res = await setOrderData(params)
    return res
  },

  async setCartEvents({ commit }, params = {}) {
    const res = await setCartEventsData(params)
    return res
  },

  async setCustomerCartBrevo({ commit }, params = {}) {
    const res = await setCustomerCartBrevoData(params)
    return res
  },

  // eslint-disable-next-line no-unused-vars
  async setCheckoutOrder({ commit }, params = {}) {
    const res = await setCheckoutOrderData(params)
    return res
  },
  async jwtKeyGenerate(_) {
    let data = {
      action: 'jwt_key_generate',
    }
    const res = await generateJwtKey(data)
    return res
  },
  async getCartTotalItem({ commit }) {
    let data = {
      action: 'get_cart_total_item',
    }
    const res = await getCartTotal(data)
    commit('SET_CART_TOTAL_ITEMS', res)
    return res
  },
  async setCodePromo({ commit }, params = {}) {
    const res = await setPromoCode(params)
    commit('SET_CODE_PROMO_DATA', res.data)
    return res
  },
  async clearCart({ commit }) {
    let data = {
      action: 'clear_cart',
    }
    const res = await clear(data)
    commit('CLEAR_CART')
    return res
  },
  async getEligiblePaymentPlans({ state, commit, getters }, { amount }) {
    let availablePlans = []
    if (amount > 50 && amount < 8000) {
      const eligibilityResp = await checkEligibility(amount)
      if (!eligibilityResp.data) {
        commit('SET_NURSE_MESSAGE', state.nurseMessages[0])
        commit('SHOW_NURSE')
        availablePlans = [
          {
            label: 'Paiement immédiat par Carte Bancaire',
            value: 'immediate',
            id: 'immediate',
          },
        ]
        return availablePlans
      }
      const eligibility = eligibilityResp.data
      const plan1x = lodash.find(eligibility, function (o) {
        return o.installments_count == 1 && o.eligible
      })
      if (plan1x) {
        commit('SET_IS_1X_ELIGIBLE', true)
        availablePlans.push({
          label: 'Paiement en 1 fois',
          value: '1x',
        })
      }
      const plan3x = lodash.find(eligibility, function (o) {
        return o.installments_count == 3 && o.eligible
      })
      if (plan3x) {
        commit('SET_IS_3X_ELIGIBLE', true)
        availablePlans.push({
          label: 'Paiement en 3 fois sans frais',
          value: '3x',
        })
      }
      const plan4x = lodash.find(eligibility, function (o) {
        return o.installments_count == 4 && o.eligible
      })
      if (plan4x) {
        commit('SET_IS_4X_ELIGIBLE', true)
        availablePlans.push({
          label: 'Paiement en 4 fois sans frais',
          value: '4x',
        })
      }
      if (plan3x || plan4x) {
        commit('SET_NURSE_MESSAGE', state.nurseMessages[1])
        commit('SHOW_NURSE')
      } else {
        commit('SET_NURSE_MESSAGE', state.nurseMessages[0])
        commit('HIDE_NURSE')
      }

      availablePlans = lodash.unionBy(availablePlans, 'value')
      commit('SET_PAYMENT_PLANS', availablePlans)
    } else if (amount) {
      availablePlans = [
        {
          label: 'Paiement immédiat par Carte Bancaire',
          value: 'immediate',
          id: 'immediate',
        },
      ]
    }
    commit('SET_PAYMENT_PLANS', availablePlans)
    return availablePlans
  },
}

const getters = {
  getField,
  trainingPriceWithTax: (state) => (trainingPrice) => {
    console.log('state.vat ', state.vat)
    return trainingPrice * state.vat + trainingPrice
  },
  immediateTrainingsPrice(state) {
    if (state.cartData.length) {
      let v = 0
      return state.cartData.reduce(function (sum, current) {
        if (isImmediateTraining(current)) {
          v = sum + current.event.price
        }
        return parseFloat(v.toFixed(2))
      }, 0)
    } else return 0
  },
  trainingsPrice(state) {
    if (state.cartData.length)
      return state.cartData.reduce(function (sum, current) {
        let price = current?.event?.priceWithPromo ?? current?.event?.price
        let v = sum + price
        return parseFloat(v.toFixed(2))
      }, 0)
    else return 0
  },
  servicePrice(state) {
    if (state.cartData.length) {
      return state.cartData.reduce(function (sum) {
        return parseFloat(sum.toFixed(2))
      }, 0)
    } else return 0
  },
  immediateServicePrice(state) {
    if (state.cartData.length) {
      let trTab = []
      lodash.forEach(state.cartData, (tr) => {
        if (isImmediateTraining(tr)) {
          trTab.push(tr)
        }
      })
      return trTab.reduce(function (sum) {
        return parseFloat(sum.toFixed(2))
      }, 0)
    } else return 0
  },
  trainingsPriceWithTax(state, getters) {
    let v = getters.trainingsPrice * state.vat + getters.trainingsPrice
    return v.toFixed(2)
  },
  immediateTrainingsPriceWithTax(state, getters) {
    let v =
      getters.immediateTrainingsPrice * state.vat +
      getters.immediateTrainingsPrice
    return v.toFixed(2)
  },
  servicePriceWithTax(state, getters) {
    let v = getters.servicePrice * state.vat + getters.servicePrice
    return v.toFixed(2)
  },
  totalPriceTaxFree(state, getters) {
    let v = getters.trainingsPrice + getters.servicePrice
    return parseFloat(v.toFixed(2))
  },
  totalPriceTaxFreeWithPromo(state, getters) {
    const totalPriceTaxFree = getters.totalPriceTaxFree
    if (state.codePromoData && state.codePromoData?.remiseType?.code == 1) {
      if (state.codePromoData?.reduction == 'amount') {
        if (state.codePromoData?.excludedCourses.length) {
          let totalAmountExcludedCourses = state.cartData.reduce((result) => {
            let t = 0
            if (result?.event?.excludedCourse) {
              t = t + result.event.price
            }
            return t
          })
          console.log('totalAmountExcludedCourses ', totalAmountExcludedCourses)
          return parseFloat(
            (totalPriceTaxFree - state.codePromoData?.reductionValue).toFixed(2)
          )
        } else
          return parseFloat(
            (totalPriceTaxFree - state.codePromoData?.reductionValue).toFixed(2)
          )
      } else if (state.codePromoData?.reduction == 'percent') {
        let promoPrice = parseFloat(
          (
            (totalPriceTaxFree * state.codePromoData?.reductionValue) /
            100
          ).toFixed(2)
        )
        if (state.codePromoData?.excludedCourses.length) {
          const excludedCourses = state.cartData.filter(
            (item) => item?.event?.excludedCourse
          )
          let totalAmountExcludedCourses = excludedCourses.reduce(
            (sum, { event }) => sum + event.price,
            0
          )
          promoPrice = parseFloat(
            (
              ((totalPriceTaxFree - totalAmountExcludedCourses) *
                state.codePromoData?.reductionValue) /
              100
            ).toFixed(2)
          )
        }
        return totalPriceTaxFree - promoPrice
      }
    } else if (
      state.codePromoData &&
      (state.codePromoData?.remiseType?.code == 2 ||
        state.codePromoData?.remiseType?.code == 3)
    ) {
      return state.cartData.reduce(function (sum, current) {
        let price = current.event?.priceWithPromo ?? current.event?.price
        let v = sum + price
        return parseFloat(v.toFixed(2))
      }, 0)
    }
  },
  immediateTotalPriceTaxFree(state, getters) {
    let v = getters.immediateTrainingsPrice + getters.immediateServicePrice
    return parseFloat(v.toFixed(2))
  },
  totalPriceWithFree(state, getters) {
    let v = getters.totalPriceTaxFree * state.vat
    return parseFloat(v.toFixed(2))
  },
  immediateTotalPriceWithFree(state, getters) {
    let v = getters.immediateTotalPriceTaxFree * state.vat
    return parseFloat(v.toFixed(2))
  },
  totalPrice(state, getters) {
    let v = 0
    if (state.codePromoData && state.codePromoData?.remiseType?.code == 1) {
      v =
        getters.totalPriceTaxFreeWithPromo * state.vat +
        getters.totalPriceTaxFreeWithPromo
    } else {
      v = getters.totalPriceTaxFree * state.vat + getters.totalPriceTaxFree
    }
    return parseFloat(v.toFixed(2))
  },
  immediateTotalPrice(state, getters) {
    let v =
      getters.immediateTotalPriceTaxFree * state.vat +
      getters.immediateTotalPriceTaxFree
    return parseFloat(v.toFixed(2))
  },
  cartTotalItems(state) {
    return state.cartTotalItems
  },
  user(state) {
    return state.user
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
